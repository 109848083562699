import {
  withAlignment,
  withRtlSupport,
} from '@skyscanner/backpack-web/bpk-component-icon';
import BpkAccountLargeIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/account';
import BpkAirlineSingleLargeIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/airline';
import BpkAirlineMultipleLargeIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/airline--multiple';
import BpkAirportsLargeIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/airports';
import BpkAlertActiveLargeIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/alert--active';
import BpkBaggageCheckedLargeIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/baggage-checked';
import BpkCalendarLargeIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/calendar';
import BpkCamperVanLargeIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/camper-van';
import BpkCarsLargeIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/cars';
import BpkFlexibleLargeIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/cars-flexible';
import BpkCityLargeIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/city';
import BpkCityCenterLargeIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/city-center';
import BpkCleaningMedicalLargeIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/cleaning-medical';
import BpkDepartLargeIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/depart';
import BpkDotLargeIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/dot';
import BpkDurationLargeIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/duration';
import BpkEcoLargeLeaf from '@skyscanner/backpack-web/bpk-component-icon/lg/eco-leaf';
import BpkElectricLargeIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/electric';
import BpkFilterLargeIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/filter';
import BpkFlightLargeIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/flight';
import BpkFlightFlexibleLargeIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/flight-flexible';
import BpkFlightLandingLargeIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/flight-landing';
import BpkFoodLargeIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/food';
import BpkGearsManualCircleLargeIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/gears-manual-circle';
import BpkHealthFitnessLargeIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/health-fitness';
import BpkHeartLargeIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/heart';
import BpkHotelsLargeIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/hotels';
import BpkHotelsPetsAllowedLargeIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/hotels--pets-allowed';
import BpkInformationLargeIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/information';
import BpkInformationCircleLargeIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/information-circle';
import BpkInsuranceLargeIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/insurance';
import BpkLandmarkLargeIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/landmark';
import BpkLanguageLargeIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/language';
import BpkLightningLargeIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/lightning';
import BpkLocationLargeIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/location';
import BpkArrowLargeIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/long-arrow-right';
import BpkMobileLargeIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/mobile';
import BpkMoneyLargeIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/money';
import BpkNewWindowLargeIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/new-window';
import BpkPaymentCardLargeIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/payment-card';
import BpkPetrolLargeIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/petrol';
import BpkPriceAlertsLargeIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/price-alerts';
import BpkPriceTagLargeIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/price-tag';
import BpkRefreshLargeIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/refresh';
import BpkRoomLargeIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/room';
import BpkSearchLargeIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/search';
import BpkSelfServiceLargeIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/self-service';
import BpkSocialLikeLargeIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/social-like';
import BpkStarLargeIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/star';
import BpkStopsLargeIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/stops';
import BpkThumbsDownLargeIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/thumbs-down';
import BpkThumbsUpLargeIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/thumbs-up';
import BpkTickCircleLargeIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/tick-circle';
import BpkTimeLargeIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/time';
import BpkTrendLargeIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/trend';
import BpkTrendDownLargeIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/trend--down';
import BpkWeatherPartlyCloudyLargeIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/weather--partly-cloudy';
import BpkAccountSmallIcon from '@skyscanner/backpack-web/bpk-component-icon/sm/account';
import BpkAirlineSingleSmallIcon from '@skyscanner/backpack-web/bpk-component-icon/sm/airline';
import BpkAirlineMultipleSmallIcon from '@skyscanner/backpack-web/bpk-component-icon/sm/airline--multiple';
import BpkAirportsSmallIcon from '@skyscanner/backpack-web/bpk-component-icon/sm/airports';
import BpkAlertActiveSmallIcon from '@skyscanner/backpack-web/bpk-component-icon/sm/alert--active';
import BpkBaggageCheckedSmallIcon from '@skyscanner/backpack-web/bpk-component-icon/sm/baggage-checked';
import BpkCalendarSmallIcon from '@skyscanner/backpack-web/bpk-component-icon/sm/calendar';
import BpkCamperVanSmallIcon from '@skyscanner/backpack-web/bpk-component-icon/sm/camper-van';
import BpkCarsSmallIcon from '@skyscanner/backpack-web/bpk-component-icon/sm/cars';
import BpkFlexibleSmallIcon from '@skyscanner/backpack-web/bpk-component-icon/sm/cars-flexible';
import BpkCitySmallIcon from '@skyscanner/backpack-web/bpk-component-icon/sm/city';
import BpkCityCenterSmallIcon from '@skyscanner/backpack-web/bpk-component-icon/sm/city-center';
import BpkCleaningMedicalSmallIcon from '@skyscanner/backpack-web/bpk-component-icon/sm/cleaning-medical';
import BpkDepartSmallIcon from '@skyscanner/backpack-web/bpk-component-icon/sm/depart';
import BpkDotSmallIcon from '@skyscanner/backpack-web/bpk-component-icon/sm/dot';
import BpkDurationSmallIcon from '@skyscanner/backpack-web/bpk-component-icon/sm/duration';
import BpkEcoSmallLeaf from '@skyscanner/backpack-web/bpk-component-icon/sm/eco-leaf';
import BpkElectricSmallIcon from '@skyscanner/backpack-web/bpk-component-icon/sm/electric';
import BpkFilterSmallIcon from '@skyscanner/backpack-web/bpk-component-icon/sm/filter';
import BpkFlightSmallIcon from '@skyscanner/backpack-web/bpk-component-icon/sm/flight';
import BpkFlightFlexibleSmallIcon from '@skyscanner/backpack-web/bpk-component-icon/sm/flight-flexible';
import BpkFlightLandingSmallIcon from '@skyscanner/backpack-web/bpk-component-icon/sm/flight-landing';
import BpkFoodSmallIcon from '@skyscanner/backpack-web/bpk-component-icon/sm/food';
import BpkGearsManualCircleSmallIcon from '@skyscanner/backpack-web/bpk-component-icon/sm/gears-manual-circle';
import BpkHealthFitnessSmallIcon from '@skyscanner/backpack-web/bpk-component-icon/sm/health-fitness';
import BpkHeartSmallIcon from '@skyscanner/backpack-web/bpk-component-icon/sm/heart';
import BpkHotelsSmallIcon from '@skyscanner/backpack-web/bpk-component-icon/sm/hotels';
import BpkHotelsPetsAllowedSmallIcon from '@skyscanner/backpack-web/bpk-component-icon/sm/hotels--pets-allowed';
import BpkInformationSmallIcon from '@skyscanner/backpack-web/bpk-component-icon/sm/information';
import BpkInformationCircleSmallIcon from '@skyscanner/backpack-web/bpk-component-icon/sm/information-circle';
import BpkInsuranceSmallIcon from '@skyscanner/backpack-web/bpk-component-icon/sm/insurance';
import BpkLandmarkSmallIcon from '@skyscanner/backpack-web/bpk-component-icon/sm/landmark';
import BpkLanguageSmallIcon from '@skyscanner/backpack-web/bpk-component-icon/sm/language';
import BpkLightningSmallIcon from '@skyscanner/backpack-web/bpk-component-icon/sm/lightning';
import BpkLocationSmallIcon from '@skyscanner/backpack-web/bpk-component-icon/sm/location';
import BpkArrowSmallIcon from '@skyscanner/backpack-web/bpk-component-icon/sm/long-arrow-right';
import BpkMobileSmallIcon from '@skyscanner/backpack-web/bpk-component-icon/sm/mobile';
import BpkMoneySmallIcon from '@skyscanner/backpack-web/bpk-component-icon/sm/money';
import BpkNewWindowSmallIcon from '@skyscanner/backpack-web/bpk-component-icon/sm/new-window';
import BpkPaymentCardSmallIcon from '@skyscanner/backpack-web/bpk-component-icon/sm/payment-card';
import BpkPetrolSmallIcon from '@skyscanner/backpack-web/bpk-component-icon/sm/petrol';
import BpkPriceAlertsSmallIcon from '@skyscanner/backpack-web/bpk-component-icon/sm/price-alerts';
import BpkPriceTagSmallIcon from '@skyscanner/backpack-web/bpk-component-icon/sm/price-tag';
import BpkRefreshSmallIcon from '@skyscanner/backpack-web/bpk-component-icon/sm/refresh';
import BpkRoomSmallIcon from '@skyscanner/backpack-web/bpk-component-icon/sm/room';
import BpkSearchSmallIcon from '@skyscanner/backpack-web/bpk-component-icon/sm/search';
import BpkSelfServiceSmallIcon from '@skyscanner/backpack-web/bpk-component-icon/sm/self-service';
import BpkSocialLikeSmallIcon from '@skyscanner/backpack-web/bpk-component-icon/sm/social-like';
import BpkStarSmallIcon from '@skyscanner/backpack-web/bpk-component-icon/sm/star';
import BpkStopsSmallIcon from '@skyscanner/backpack-web/bpk-component-icon/sm/stops';
import BpkThumbsDownSmallIcon from '@skyscanner/backpack-web/bpk-component-icon/sm/thumbs-down';
import BpkThumbsUpSmallIcon from '@skyscanner/backpack-web/bpk-component-icon/sm/thumbs-up';
import BpkTickCircleSmallIcon from '@skyscanner/backpack-web/bpk-component-icon/sm/tick-circle';
import BpkTimeSmallIcon from '@skyscanner/backpack-web/bpk-component-icon/sm/time';
import BpkTrendSmallIcon from '@skyscanner/backpack-web/bpk-component-icon/sm/trend';
import BpkTrendDownSmallIcon from '@skyscanner/backpack-web/bpk-component-icon/sm/trend--down';
import BpkWeatherPartlyCloudySmallIcon from '@skyscanner/backpack-web/bpk-component-icon/sm/weather--partly-cloudy';
import {
  iconSizeLg,
  iconSizeSm,
  lineHeightBase,
} from 'bpk-tokens/tokens/base.es6';

import type { Icon } from '@skyscanner-internal/falcon-shared-types/types/Icons';

export const ICON_SIZE = {
  LARGE: 'large',
  SMALL: 'small',
} as const;

type IconSize = (typeof ICON_SIZE)[keyof typeof ICON_SIZE];

export default (icon: Icon, size: IconSize = ICON_SIZE.LARGE) => {
  let IconComponent = null;
  switch (icon) {
    case 'search':
      IconComponent =
        size === ICON_SIZE.LARGE
          ? withRtlSupport(BpkSearchLargeIcon)
          : withRtlSupport(BpkSearchSmallIcon);
      break;
    case 'flight':
      IconComponent =
        size === ICON_SIZE.LARGE
          ? withRtlSupport(BpkFlightLargeIcon)
          : withRtlSupport(BpkFlightSmallIcon);
      break;
    case 'money':
      IconComponent =
        size === ICON_SIZE.LARGE
          ? withRtlSupport(BpkMoneyLargeIcon)
          : withRtlSupport(BpkMoneySmallIcon);
      break;
    case 'flight-flexible':
      IconComponent =
        size === ICON_SIZE.LARGE
          ? withRtlSupport(BpkFlightFlexibleLargeIcon)
          : withRtlSupport(BpkFlightFlexibleSmallIcon);
      break;
    case 'alert--active':
      IconComponent =
        size === ICON_SIZE.LARGE
          ? withRtlSupport(BpkAlertActiveLargeIcon)
          : withRtlSupport(BpkAlertActiveSmallIcon);
      break;
    case 'room':
      IconComponent =
        size === ICON_SIZE.LARGE
          ? withRtlSupport(BpkRoomLargeIcon)
          : withRtlSupport(BpkRoomSmallIcon);
      break;
    case 'insurance':
      IconComponent =
        size === ICON_SIZE.LARGE
          ? withRtlSupport(BpkInsuranceLargeIcon)
          : withRtlSupport(BpkInsuranceSmallIcon);
      break;
    case 'filter':
      IconComponent =
        size === ICON_SIZE.LARGE
          ? withRtlSupport(BpkFilterLargeIcon)
          : withRtlSupport(BpkFilterSmallIcon);
      break;
    case 'airports':
      IconComponent =
        size === ICON_SIZE.LARGE
          ? withRtlSupport(BpkAirportsLargeIcon)
          : withRtlSupport(BpkAirportsSmallIcon);
      break;
    case 'airline':
      IconComponent =
        size === ICON_SIZE.LARGE
          ? withRtlSupport(BpkAirlineMultipleLargeIcon)
          : withRtlSupport(BpkAirlineMultipleSmallIcon);
      break;
    case 'airlineSingle':
      IconComponent =
        size === ICON_SIZE.LARGE
          ? withRtlSupport(BpkAirlineSingleLargeIcon)
          : withRtlSupport(BpkAirlineSingleSmallIcon);
      break;
    case 'time':
      IconComponent =
        size === ICON_SIZE.LARGE
          ? withRtlSupport(BpkTimeLargeIcon)
          : withRtlSupport(BpkTimeSmallIcon);
      break;
    case 'cleaning-medical':
      IconComponent =
        size === ICON_SIZE.LARGE
          ? withRtlSupport(BpkCleaningMedicalLargeIcon)
          : withRtlSupport(BpkCleaningMedicalSmallIcon);
      break;
    case 'calendar':
      IconComponent =
        size === ICON_SIZE.LARGE
          ? withRtlSupport(BpkCalendarLargeIcon)
          : withRtlSupport(BpkCalendarSmallIcon);
      break;
    case 'flight-landing':
      IconComponent =
        size === ICON_SIZE.LARGE
          ? withRtlSupport(BpkFlightLandingLargeIcon)
          : withRtlSupport(BpkFlightLandingSmallIcon);
      break;
    case 'information':
      IconComponent =
        size === ICON_SIZE.LARGE
          ? withRtlSupport(BpkInformationLargeIcon)
          : withRtlSupport(BpkInformationSmallIcon);
      break;
    case 'information-circle':
      IconComponent =
        size === ICON_SIZE.LARGE
          ? withRtlSupport(BpkInformationCircleLargeIcon)
          : withRtlSupport(BpkInformationCircleSmallIcon);
      break;
    case 'refresh':
      IconComponent =
        size === ICON_SIZE.LARGE
          ? withRtlSupport(BpkRefreshLargeIcon)
          : withRtlSupport(BpkRefreshSmallIcon);
      break;
    case 'tick-circle':
      IconComponent =
        size === ICON_SIZE.LARGE
          ? withRtlSupport(BpkTickCircleLargeIcon)
          : withRtlSupport(BpkTickCircleSmallIcon);
      break;
    case 'cars-flexible':
      IconComponent =
        size === ICON_SIZE.LARGE
          ? withRtlSupport(BpkFlexibleLargeIcon)
          : withRtlSupport(BpkFlexibleSmallIcon);
      break;
    case 'cars':
      IconComponent =
        size === ICON_SIZE.LARGE
          ? withRtlSupport(BpkCarsLargeIcon)
          : withRtlSupport(BpkCarsSmallIcon);
      break;
    case 'petrol':
      IconComponent =
        size === ICON_SIZE.LARGE
          ? withRtlSupport(BpkPetrolLargeIcon)
          : withRtlSupport(BpkPetrolSmallIcon);
      break;
    case 'camper-van':
      IconComponent =
        size === ICON_SIZE.LARGE
          ? withRtlSupport(BpkCamperVanLargeIcon)
          : withRtlSupport(BpkCamperVanSmallIcon);
      break;
    case 'self-service':
      IconComponent =
        size === ICON_SIZE.LARGE
          ? withRtlSupport(BpkSelfServiceLargeIcon)
          : withRtlSupport(BpkSelfServiceSmallIcon);
      break;
    case 'electric':
      IconComponent =
        size === ICON_SIZE.LARGE
          ? withRtlSupport(BpkElectricLargeIcon)
          : withRtlSupport(BpkElectricSmallIcon);
      break;
    case 'location':
      IconComponent =
        size === ICON_SIZE.LARGE
          ? withRtlSupport(BpkLocationLargeIcon)
          : withRtlSupport(BpkLocationSmallIcon);
      break;
    case 'mobile':
      IconComponent =
        size === ICON_SIZE.LARGE
          ? withRtlSupport(BpkMobileLargeIcon)
          : withRtlSupport(BpkMobileSmallIcon);
      break;
    case 'stops':
      IconComponent =
        size === ICON_SIZE.LARGE
          ? withRtlSupport(BpkStopsLargeIcon)
          : withRtlSupport(BpkStopsSmallIcon);
      break;
    case 'trend':
      IconComponent =
        size === ICON_SIZE.LARGE
          ? withRtlSupport(BpkTrendLargeIcon)
          : withRtlSupport(BpkTrendSmallIcon);
      break;
    case 'trend--down':
      IconComponent =
        size === ICON_SIZE.LARGE
          ? withRtlSupport(BpkTrendDownLargeIcon)
          : withRtlSupport(BpkTrendDownSmallIcon);
      break;
    case 'account':
      IconComponent =
        size === ICON_SIZE.LARGE
          ? withRtlSupport(BpkAccountLargeIcon)
          : withRtlSupport(BpkAccountSmallIcon);
      break;
    case 'gears-manual-circle':
      IconComponent =
        size === ICON_SIZE.LARGE
          ? withRtlSupport(BpkGearsManualCircleLargeIcon)
          : withRtlSupport(BpkGearsManualCircleSmallIcon);
      break;
    case 'language':
      IconComponent =
        size === ICON_SIZE.LARGE
          ? withRtlSupport(BpkLanguageLargeIcon)
          : withRtlSupport(BpkLanguageSmallIcon);
      break;
    case 'star':
      IconComponent =
        size === ICON_SIZE.LARGE
          ? withRtlSupport(BpkStarLargeIcon)
          : withRtlSupport(BpkStarSmallIcon);
      break;
    case 'city':
      IconComponent =
        size === ICON_SIZE.LARGE
          ? withRtlSupport(BpkCityLargeIcon)
          : withRtlSupport(BpkCitySmallIcon);
      break;
    case 'cityCenter':
      IconComponent =
        size === ICON_SIZE.LARGE
          ? withRtlSupport(BpkCityCenterLargeIcon)
          : withRtlSupport(BpkCityCenterSmallIcon);
      break;
    case 'priceAlerts':
      IconComponent =
        size === ICON_SIZE.LARGE
          ? withRtlSupport(BpkPriceAlertsLargeIcon)
          : withRtlSupport(BpkPriceAlertsSmallIcon);
      break;
    case 'priceTag':
      IconComponent =
        size === ICON_SIZE.LARGE
          ? withRtlSupport(BpkPriceTagLargeIcon)
          : withRtlSupport(BpkPriceTagSmallIcon);
      break;
    case 'duration':
      IconComponent =
        size === ICON_SIZE.LARGE
          ? withRtlSupport(BpkDurationLargeIcon)
          : withRtlSupport(BpkDurationSmallIcon);
      break;
    case 'depart':
      IconComponent =
        size === ICON_SIZE.LARGE
          ? withRtlSupport(BpkDepartLargeIcon)
          : withRtlSupport(BpkDepartSmallIcon);
      break;
    case 'heart':
      IconComponent =
        size === ICON_SIZE.LARGE
          ? withRtlSupport(BpkHeartLargeIcon)
          : withRtlSupport(BpkHeartSmallIcon);
      break;
    case 'socialLike':
      IconComponent =
        size === ICON_SIZE.LARGE
          ? withRtlSupport(BpkSocialLikeLargeIcon)
          : withRtlSupport(BpkSocialLikeSmallIcon);
      break;
    case 'thumbsUp':
      IconComponent =
        size === ICON_SIZE.LARGE
          ? withRtlSupport(BpkThumbsUpLargeIcon)
          : withRtlSupport(BpkThumbsUpSmallIcon);
      break;
    case 'hotelsPetsAllowed':
      IconComponent =
        size === ICON_SIZE.LARGE
          ? withRtlSupport(BpkHotelsPetsAllowedLargeIcon)
          : withRtlSupport(BpkHotelsPetsAllowedSmallIcon);
      break;
    case 'hotels':
      IconComponent =
        size === ICON_SIZE.LARGE
          ? withRtlSupport(BpkHotelsLargeIcon)
          : withRtlSupport(BpkHotelsSmallIcon);
      break;
    case 'weather-partly-cloudy':
      IconComponent =
        size === ICON_SIZE.LARGE
          ? withRtlSupport(BpkWeatherPartlyCloudyLargeIcon)
          : withRtlSupport(BpkWeatherPartlyCloudySmallIcon);
      break;
    case 'eco-leaf':
      IconComponent =
        size === ICON_SIZE.LARGE
          ? withRtlSupport(BpkEcoLargeLeaf)
          : withRtlSupport(BpkEcoSmallLeaf);
      break;
    case 'baggage-checked':
      IconComponent =
        size === ICON_SIZE.LARGE
          ? withRtlSupport(BpkBaggageCheckedLargeIcon)
          : withRtlSupport(BpkBaggageCheckedSmallIcon);
      break;
    case 'food':
      IconComponent =
        size === ICON_SIZE.LARGE
          ? withRtlSupport(BpkFoodLargeIcon)
          : withRtlSupport(BpkFoodSmallIcon);
      break;
    case 'health-fitness':
      IconComponent =
        size === ICON_SIZE.LARGE
          ? withRtlSupport(BpkHealthFitnessLargeIcon)
          : withRtlSupport(BpkHealthFitnessSmallIcon);
      break;
    case 'payment-card':
      IconComponent =
        size === ICON_SIZE.LARGE
          ? withRtlSupport(BpkPaymentCardLargeIcon)
          : withRtlSupport(BpkPaymentCardSmallIcon);
      break;
    case 'thumbs-down':
      IconComponent =
        size === ICON_SIZE.LARGE
          ? withRtlSupport(BpkThumbsDownLargeIcon)
          : withRtlSupport(BpkThumbsDownSmallIcon);
      break;
    case 'new-window':
      IconComponent =
        size === ICON_SIZE.LARGE
          ? withRtlSupport(BpkNewWindowLargeIcon)
          : withRtlSupport(BpkNewWindowSmallIcon);
      break;
    case 'arrow':
      IconComponent =
        size === ICON_SIZE.LARGE
          ? withRtlSupport(BpkArrowLargeIcon)
          : withRtlSupport(BpkArrowSmallIcon);
      break;
    case 'landmark':
      IconComponent =
        size === ICON_SIZE.LARGE
          ? withRtlSupport(BpkLandmarkLargeIcon)
          : withRtlSupport(BpkLandmarkSmallIcon);
      break;
    case 'lightning':
      IconComponent =
        size === ICON_SIZE.LARGE
          ? withRtlSupport(BpkLightningLargeIcon)
          : withRtlSupport(BpkLightningSmallIcon);
      break;
    case 'dot':
      IconComponent =
        size === ICON_SIZE.LARGE
          ? withRtlSupport(BpkDotLargeIcon)
          : withRtlSupport(BpkDotSmallIcon);
      break;
    default:
      break;
  }
  if (!IconComponent) {
    return () => null;
  }

  if (size === ICON_SIZE.LARGE) {
    return withAlignment(IconComponent, lineHeightBase, iconSizeLg);
  }

  return withAlignment(IconComponent, lineHeightBase, iconSizeSm);
};
